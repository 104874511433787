import React from 'react';

function Publications() {
  let dirigent=process.env.PUBLIC_URL+"/papers/dirigent_sosp24.pdf"
  let dejavu=process.env.PUBLIC_URL+"/papers/dejavu_ICML24.pdf"
  let pecan=process.env.PUBLIC_URL+"/papers/pecan_atc24.pdf"
  let boxerCIDR=process.env.PUBLIC_URL+"/papers/off-the-shelf-serverless_CIDR24.pdf"
  let orion=process.env.PUBLIC_URL+"/papers/orion_eurosys24.pdf"
  let tfdata_service=process.env.PUBLIC_URL+"/papers/tfdata_service_SoCC23.pdf"
  let plumber=process.env.PUBLIC_URL+"/papers/plumber_mlsys22.pdf"
  let invitro=process.env.PUBLIC_URL+"/papers/In-Vitro_serverless_WORDS23.pdf"
  let dandelion_vision=process.env.PUBLIC_URL+"/papers/function_as_a_function_SoCC23.pdf"
  let understanding_faas_cluster_mgmt=process.env.PUBLIC_URL+"/papers/understanding_faas_cluster_management_WORDS23.pdf"
  let rethinking_serverless=process.env.PUBLIC_URL+"/papers/rethinking_serverless_SDA_VLDB23.pdf"
  let per_query_engines=process.env.PUBLIC_URL+"/papers/ephemeral_per_query_engines_serverless_SDA_VLDB23.pdf"
  let nvm_db=process.env.PUBLIC_URL+"/papers/nvm_db_vldb23.pdf"
  let hash_tables=process.env.PUBLIC_URL+"/papers/vectorized_hash_tables_vldb23.pdf"
  let modyn_vision=process.env.PUBLIC_URL+"/papers/MLonDynamicData_EuroMLSys23.pdf"
  let cachew=process.env.PUBLIC_URL+"/papers/cachew_atc22.pdf"
  let learning_rate=process.env.PUBLIC_URL+"/papers/learning_rate_distribml22.pdf"
  let rail_tos=process.env.PUBLIC_URL+"/papers/rail_tos.pdf"
  let sonic_atc21=process.env.PUBLIC_URL+"/papers/sonic_atc21.pdf"
  let serverless_ml_sigmod21=process.env.PUBLIC_URL+"/papers/serverless-sigmod2021.pdf"
  let serverless_spma20=process.env.PUBLIC_URL+"/papers/serverless_clusters_spma20.pdf"
  let optimuscloud_atc20=process.env.PUBLIC_URL+"/papers/optimuscloud_atc20.pdf"
  let crail_atc19=process.env.PUBLIC_URL+"/papers/crail-atc19.pdf";
  let thesis=process.env.PUBLIC_URL+"/papers/thesis.pdf";
  let pocket=process.env.PUBLIC_URL+"/papers/pocket.pdf";
  let serverless_atc18=process.env.PUBLIC_URL+"/papers/serverless-atc18.pdf";
  let selecta_sysml18=process.env.PUBLIC_URL+"/papers/selecta-sysml18.pdf";
  let selecta_atc18=process.env.PUBLIC_URL+"/papers/selecta-atc18.pdf";
  let reflex=process.env.PUBLIC_URL+"/papers/reflex.pdf";
  let disagg_hotstorage17=process.env.PUBLIC_URL+"/papers/disagg-hotstorage17.pdf";
  let eurosys_flash_disagg=process.env.PUBLIC_URL+"/papers/eurosys2016.pdf";
  let ix_osdi14=process.env.PUBLIC_URL+"/papers/ix-osdi2014.pdf";
  let ix_tocs=process.env.PUBLIC_URL+"/papers/IX_TOCS.pdf";



  return (
    <div data-nosnippet>
      <br />
      <p><span style={{color: "#333333"}}> [<b>SOSP</b>]</span> <a href={dirigent} target= "_blank" rel="noopener noreferrer"><b>Dirigent: Lightweight Serverless Orchestration</b></a> <br /> Lazar Cvetković, François Costa, Mihajlo Djokic, Michal Friedman, <b>Ana Klimovic</b>. <br />
      Proceedings of the ACM Symposium on Operating Systems Principles, 2024.</p>
      <p><span style={{color: "#333333"}}> [<b>ICML</b>]</span> <a href={dejavu} target= "_blank" rel="noopener noreferrer"><b>DéjàVu: KV-cache Streaming for Fast, Fault-tolerant Generative LLM Serving</b></a> <br /> Foteini Strati, Sara McAllister, Amar Phanishayee, Jakub Tarnawski, <b>Ana Klimovic</b>.<br />
      Proceedings of the International Conference on Machine Learning (ICML), 2024.</p>
      <p><span style={{color: "#333333"}}> [<b>ATC</b>]</span> <a href={pecan} target= "_blank" rel="noopener noreferrer"><b>Pecan: Cost-Efficient ML Data Preprocessing with Automatic Transformation Ordering and Hybrid Placement</b></a> <br /> Dan Graur*, Oto Mraz*, Sepehr Pourghannad, Muyu Li, Chandramohan A. Thekkath, <b>Ana Klimovic</b>.<br />
      Proceedings of the USENIX Annual Technical Conference (ATC), 2024.</p>
      <p><span style={{color: "#333333"}}> [<b>CIDR</b>]</span> <a href={boxerCIDR} target= "_blank" rel="noopener noreferrer"><b>Off-the-shelf Data Analytics on Serverless</b></a> <br /> Michael Wawrzoniak, Gianluca Moro, Rodrigo Bruno, <b>Ana Klimovic</b>, Gustavo Alonso. <br />
      Proceedings of the Conference on Innovative Data Systems Research (CIDR), 2024.</p>
      <p><span style={{color: "#333333"}}> [<b>EuroSys</b>]</span> <a href={orion} target= "_blank" rel="noopener noreferrer"><b>Orion: Interference-aware, Fine-grained GPU Sharing for ML Applications</b></a> <br /> Foteini Strati, Xianzhe Ma, <b>Ana Klimovic</b>. <br />
      Proceedings of the European Conference on Computer Systems (EuroSys), 2024.</p>
      <p><span style={{color: "#333333"}}> [<b>SoCC</b>]</span> <a href={dandelion_vision} target= "_blank" rel="noopener noreferrer"><b>Function as a Function</b></a> <br /> Tom Kuchler, Michael Giardino, Timothy Roscoe, <b>Ana Klimovic</b>. <br />
      Proceedings of the ACM Symposium on Cloud Computing (SoCC), 2023.</p>
      <p><span style={{color: "#333333"}}> [<b>SoCC</b>]</span> <a href={tfdata_service} target= "_blank" rel="noopener noreferrer"><b>tf.data service: A Case for Disaggregating ML Input Data Processing</b></a> <br /> Andrew Audibert, Yang Chen, Dan Graur, <b>Ana Klimovic</b>, Jiri Simsa, Chandramohan A. Thekkath. <br />
      Proceedings of the ACM Symposium on Cloud Computing (SoCC), 2023.</p>
      <p><span style={{color: "#333333"}}> [<b>WORDS</b>]</span> <a href={understanding_faas_cluster_mgmt} target= "_blank" rel="noopener noreferrer"><b>Understanding the Neglected Cost of Serverless Cluster Management</b></a> <br /> Lazar Cvetković, Rodrigo Fonseca, <b>Ana Klimovic</b>. <br />
      Proceedings of the Workshop on Resource Disaggregation and Serverless (WORDS) at SOSP, 2023.</p>
      <p><span style={{color: "#333333"}}> [<b>WORDS</b>]</span> <a href={invitro} target= "_blank" rel="noopener noreferrer"><b>Enabling In-Vitro Serverless Systems Research</b></a> <br /> Dmitrii Ustiugov, Dohyun Park, Lazar Cvetković, Mihajlo Djokić, Hongyu Hè, Boris Grot, <b>Ana Klimovic</b>. <br />
      Proceedings of the Workshop on Resource Disaggregation and Serverless (WORDS) at SOSP, 2023.</p>
      <p><span style={{color: "#333333"}}> [<b>VLDB</b>]</span> <a href={nvm_db} target= "_blank" rel="noopener noreferrer"><b>NVM: Is it Not Very Meaningful for Databases?</b></a> <br /> Dimitrios Koutsoukos, Raghav Bhartia, Michal Friedman, <b>Ana Klimovic</b>, Gustavo Alonso. <br />
      Proceedings of the International Conference on Very Large Databases (VLDB), 2023.</p>
      <p><span style={{color: "#333333"}}> [<b>VLDB</b>]</span> <a href={hash_tables} target= "_blank" rel="noopener noreferrer"><b>Analyzing Vectorized Hash Tables Across CPU Architectures</b></a> <br /> Maximilian Böther, Lawrence Benson, <b>Ana Klimovic</b>, Tilmann Rabl. <br />
      Proceedings of the International Conference on Very Large Databases (VLDB), 2023.</p>
      <p><span style={{color: "#333333"}}> [<b>VLDB</b>]</span> <a href="https://arxiv.org/abs/2204.01457" target= "_blank" rel="noopener noreferrer"><b>SHiFT: An Efficient, Flexible Search Engine for Transfer Learning</b></a> <br /> Cedric Renggli, Xiaozhe Yao, Luka Kolar, Luka Rimanic, <b>Ana Klimovic</b>, Ce Zhang. <br />
      Proceedings of the International Conference on Very Large Databases (VLDB), 2023.</p>
      <p><span style={{color: "#333333"}}> [<b>SDA</b>]</span> <a href={rethinking_serverless} target= "_blank" rel="noopener noreferrer"><b>Rethinking Serverless Computing: from the Programming Model to the Platform Design</b></a> <br /> Gustavo Alonso, <b>Ana Klimovic</b>, Tom Kuchler, Michael Wawrzoniak. <br />
      Proceedings of VLDB Workshop on Serverless Data Analytics, 2023.</p>
      <p><span style={{color: "#333333"}}> [<b>SDA</b>]</span> <a href={per_query_engines} target= "_blank" rel="noopener noreferrer"><b>Ephemeral Per-query Engines for Serverless Analytics</b></a> <br /> Michael Wawrzoniak, Rodrigo Bruno, <b>Ana Klimovic</b>, Gustavo Alonso. <br />
      Proceedings of VLDB Workshop on Serverless Data Analytics, 2023.</p>
      <p><span style={{color: "#333333"}}> [<b>EuroMLSys</b>]</span> <a href={modyn_vision} target= "_blank" rel="noopener noreferrer"><b>Towards a Platform and Benchmark Suite for Model Training on Dynamic Datasets</b></a> <br /> Maximilian Böther, Foteini Strati, Viktor Gsteiger, <b>Ana Klimovic</b>. <br />
      Proceedings of the Workshop on Machine Learning and Systems (EuroMLSys), 2023.</p>
      <p><span style={{color: "#333333"}}> [<b>ATC</b>]</span> <a href={cachew} target= "_blank" rel="noopener noreferrer"><b>Cachew: Machine Learning Input Data Processing as a Service</b></a> <br /> Dan Graur, Damien Aymon, Dan Kluser, Tanguy Albrici, Chandramohan A. Thekkath, <b>Ana Klimovic</b>.<br />
      Proceedings of the USENIX Annual Technical Conference (ATC), 2022.</p>
      <p><span style={{color: "#333333"}}> [<b>MLSys</b>]</span> <a href={plumber} target= "_blank" rel="noopener noreferrer"><b>Plumber: Diagnosing and Removing Performance Bottlenecks in Machine Learning Data Pipelines</b></a> <br /> Michael Kuchnik, <b>Ana Klimovic</b>, Jiri Simsa, George Amvrosiadis, Virgina Smith.<br />
      Proceedings of the Conference on Machine Learning and Systems (MLSys), 2022.</p>
      <p><span style={{color: "#333333"}}> [<b>DistributedML</b>]</span> <a href={learning_rate} target= "_blank" rel="noopener noreferrer"><b>Exploring Learning Rate Scaling Rules for Distributed ML Training on Transient Resources</b></a> <br /> Joel André*, Foteini Strati*, <b>Ana Klimovic</b>. <br />
      International Workshop on Distributed Machine Learning (DistributedML), 2022.</p>
      <p><span style={{color: "#333333"}}> [<b>VLDB</b>]</span> <a href="http://vldb.org/pvldb/vol14/p2945-klimovic.pdf" target= "_blank" rel="noopener noreferrer"><b>tf.data: A Machine Learning Data Processing Framework</b></a> <br /> Derek G. Murray, Jiri Simsa, <b>Ana Klimovic</b>, Ihor Indyk.<br />
      Proceedings of the International Conference on Very Large Databases (VLDB), August 2021. <a href="https://www.youtube.com/watch?v=VsOvy3eGK8Y" target= "_blank" rel="noopener noreferrer">Presentation Video.</a></p>
      <p><span style={{color: "#333333"}}> [<b>VLDB</b>]</span> <a href="http://www.vldb.org/pvldb/vol14/p3308-koutsoukos.pdf" target= "_blank" rel="noopener noreferrer"><b>Modularis: Modular Relational Analytics over Heterogeneous Distributed Platforms</b></a> <br /> Dimitris Koutsoukos, Ingo Müller, Renato Marroquín, <b>Ana Klimovic</b>, Gustavo Alonso.<br />
      Proceedings of the International Conference on Very Large Databases (VLDB), 2021.</p>
      <p><span style={{color: "#333333"}}> [<b>ATC</b>]</span> <a href={sonic_atc21} target= "_blank" rel="noopener noreferrer"><b>SONIC: Application-aware Data Passing for Chained Serverless Applications</b></a> <br /> Ashraf Mahgoub, Karthick Shankar, Subrata Mitra, <b>Ana Klimovic</b>, Somali Chaterji, Saurabh Bagchi.<br />
      Proceedings of the USENIX Annual Technical Conference (ATC), July 2021.</p>
      <p><span style={{color: "#333333"}}> [<b>SIGMOD</b>]</span> <a href={serverless_ml_sigmod21} target= "_blank" rel="noopener noreferrer"><b>Towards Demystifying Serverless Machine Learning Training</b></a> <br /> Jiawei Jiang*, Shaoduo Gan*, Yue Liu, Gustavo Alonso, <b>Ana Klimovic</b>, Ankit Singla, Wentao Wu, Ce Zhang.<br />
      Proceedings of ACM SIGMOD/PODS International Conference on Management of Data (SIGMOD), June 2021.</p>
      <p><span style={{color: "#333333"}}> [<b>TOS</b>]</span> <a href={rail_tos} target= "_blank" rel="noopener noreferrer"><b>RAIL: Predictable, Low Tail Latency for NVMe Flash</b></a> <br /> Heiner Litz, Javier Gonzalez, <b>Ana Klimovic</b>, Christos Kozyrakis.<br />
      ACM Transactions on Storage (TOS), Volume 1, Issue 1, January 2021.</p>
      <p><span style={{color: "#333333"}}> [<b>ATC</b>]</span> <a href={optimuscloud_atc20} target= "_blank" rel="noopener noreferrer"><b>OPTIMUSCLOUD: Heterogeneous Configuration Optimization for Distributed Databases in the Cloud</b></a> <br /> Ashraf Mahgoub, Alexander Michaelson Medoff, Rakesh Kumar, Subrata Mitra, <b>Ana Klimovic</b>, Somali Chaterji, Saurabh Bagchi.<br />
      Proceedings of the USENIX Annual Technical Conference (ATC), July 2020.</p>
      <p><span style={{color: "#333333"}}> [<b>SPMA</b>]</span> <a href={serverless_spma20} target= "_blank" rel="noopener noreferrer"><b>Serverless Clusters: The Missing Piece for Interactive Batch Applications?</b></a> <br /> Ingo M&uuml;ller, Rodrigo Bruno, <b>Ana Klimovic</b>, John Wilkes, Eric Sedlar, Gustavo Alonso.<br />
      Workshop on Systems for Post-Moore Architectures (SPMA), April 2020.</p>
      <p><span style={{color: "#333333"}}> [<b>ATC</b>]</span> <a href={crail_atc19} target= "_blank" rel="noopener noreferrer"><b>Unification of Temporary Storage in the NodeKernel Architecture</b></a> <br /> Patrick Stuedi, Animesh Trivedi, Jonas Pfefferle, <b>Ana Klimovic</b>, Adrian Schuepbach, Bernard Metzler. <br />
      Proceedings of the USENIX Annual Technical Conference (ATC), Renton, WA, July 2019.</p>
      <p><span style={{color: "#333333"}}> [<b>Thesis</b>]</span> <a href={thesis} target= "_blank" rel="noopener noreferrer"><b>Fast, Elastic Storage for the Cloud</b></a> <br /> <b>Ana Klimovic</b>. Doctoral Dissertation, Stanford University, June 2019. <br /> I presented my thesis work at several seminars, including the WICARCH seminar (<a href="https://www.youtube.com/watch?v=6uISJVvG5Zo" target= "_blank" rel="noopener noreferrer">presentation video</a>). </p>
      <p><span style={{color: "#333333"}}> [<b>OSDI</b>]</span> <a href={pocket} target= "_blank" rel="noopener noreferrer"><b>Pocket: Elastic Ephemeral Storage for Serverless Analytics</b></a> <br /> <b>Ana Klimovic</b>, Yawen Wang, Christos Kozyrakis, Patrick Stuedi, Animesh Trivedi, Jonas Pfefferle <br />
      Proceedings of USENIX Operating Systems Design and Implementation (OSDI), Carlsbad, CA, October 2018.</p>
      <p><span style={{color: "#333333"}}> [<b>ATC</b>]</span> <a href={serverless_atc18} target= "_blank" rel="noopener noreferrer"><b>Understanding Ephemeral Storage for Serverless Analytics</b></a> <br /> <b>Ana Klimovic</b>, Yawen Wang, Christos Kozyrakis, Patrick Stuedi, Jonas Pfefferle, Animesh Trivedi <br />
      Proceedings of the USENIX Annual Technical Conference (ATC), Boston, MA, July 2018.</p>
      <p><span style={{color: "#333333"}}>[<b>ATC</b>]</span> <a href={selecta_atc18} target= "_blank" rel="noopener noreferrer"><b>Selecta: Heterogeneous Cloud Storage Configuration for Data Analytics</b></a> <br /> <b>Ana Klimovic</b>, Heiner Litz, Christos Kozyrakis <br />
      Proceedings of the USENIX Annual Technical Conference (ATC), Boston, MA, July 2018.</p>
      <p><span style={{color: "#333333"}}>[<b>MLSys</b>]</span> <a href={selecta_sysml18} target= "_blank" rel="noopener noreferrer"><b>Learning Heterogeneous Cloud Storage Configuration for Data Analytics</b></a> <br /> <b>Ana Klimovic</b>, Heiner Litz, Christos Kozyrakis <br />
      Non-archival proceedings of the inaugural Systems and Machine Learning conference (MLSys), Stanford, CA, February 2018.</p>
      <p><span style={{color: "#333333"}}>[<b>HotStorage</b>]</span> <a href={disagg_hotstorage17} target= "_blank" rel="noopener noreferrer"><b>Understanding Rack-Scale Disaggregated Storage</b></a> <br /> Sergey Legtchenko, Hugh Williams, Kaveh Razavi, Austin Donnelly, Richard Black, Andrew Douglas, Nathanael Cheriere, Daniel Fryer, Kai Mast, Angela Demke Brown, <b>Ana Klimovic</b>, Andy Slowey, Antony Rowstron <br />
      Proceedings of the USENIX Hot Topics in Storage and File Systems (HotStorage), Santa Clara, CA, July 2017.</p>
      <p>
        {/* <img src="images/orange-star.svg" height="20"> */}
        <span style={{color: "#333333"}}>[<b>ASPLOS</b>]</span> <a href={reflex} target= "_blank" rel="noopener noreferrer"><b>ReFlex: Remote Flash ≈ Local Flash</b></a> <br />
      <b>Ana Klimovic</b>*, Heiner Litz*, Christos Kozyrakis<br />
      Proceedings of the 21st International Conference on Architectural Support for Programming Languages and Operating Systems (ASPLOS), Xi'an, China, April 2017. <span style={{color: "#38ACEC"}}> <b>Memorable Paper Award <a href="http://nvmw.ucsd.edu/2017/10/27/nvmw-memorable-paper-award/">(awarded at NVMW'18)</a>.</b> </span></p>
      <p><span style={{color: "#333333"}}>[<b>TOCS</b>]</span> <a href={ix_tocs} target= "_blank" rel="noopener noreferrer"><b>The IX Operating System: Combining Low Latency, High Throughput, and Efficiency in a Protected Dataplane</b></a> <br /> Adam Belay, George Prekas, Mia Primorac, <b>Ana Klimovic</b>, Samuel Grossman, Christos Kozyrakis, Edouard Bugnion <br />
      ACM Transactions on Computer Systems, Volume 34, Issue 4, January 2017.</p>
      <p><span style={{color: "#333333"}}>[<b>EuroSys</b>]</span> <a href={eurosys_flash_disagg} target= "_blank" rel="noopener noreferrer"><b>Flash Storage Disaggregation</b></a> <br />
      <b>Ana Klimovic</b>, Christos Kozyrakis, Eno Thereska, Binu John, Sanjeev Kumar <br />
      Proceedings of the 11th European Conference on Computer Systems (EuroSys), London, UK, April 2016.</p>
      <p><span style={{color: "#333333"}}>[<b>OSDI</b>]</span> <a href={ix_osdi14} target= "_blank" rel="noopener noreferrer"><b>IX: A Protected Dataplane Operating System for High Throughput and Low Latency</b></a><br />
      Adam Belay, George Prekas, <b>Ana Klimovic</b>, Samuel Grossman, Christos Kozyrakis, Edouard Bugnion<br />
    Proceedings of the 11th USENIX Symposium on Operating Systems Design and Implementation (OSDI), Broomfield, CO, October 2014.<span style={{color: "#38ACEC"}}> <br /><b>Best Paper Award.</b> </span></p>
        <p><span style={{color: "#333333"}}>[<b>FPT</b>]</span> <a href="http://janders.eecg.toronto.edu/pdfs/ana.pdf" target= "_blank" rel="noopener noreferrer"><b>Bitwidth-optimized Hardware Accelerators with Software Fallback</b></a><br />
      <b>Ana Klimovic</b> and Jason H. Anderson <br />
    IEEE International Conference on Field-Programmable Technology (FPT), pp. 136-143, Kyoto, Japan, December 2013. <br /></p><br />
    </div>
  );
}

export default Publications;
